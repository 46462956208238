import styled from 'styled-components'

export default styled.table`
    border-collapse: collapse;
    color: black;
    width: 100%;

    thead {
        tr {
            border-bottom: 1px solid ${(props) => props.theme.palette.gray[600]};

            th {
                padding: 1rem;
                font-size: 1.2rem;
                font-weight: 600;
                text-transform: uppercase;
                text-align: left;

                &.center {
                    text-align: center;
                }

                &.emailCheckerGroupName {
                    width: 60%;
                }

                &.emailCheckerSingleName {
                    width: 80%;
                }

                &.width100 {
                    width: 100%;
                }
            }
        }
    }

    tbody {
        tr {
            &:not(:last-child) {
                border-bottom: 1px solid
                    ${(props) => props.theme.palette.gray[100]};
            }

            &:hover {
                background-color: ${(props) => props.theme.palette.gray[100]};
            }

            td {
                padding: 1rem;
                font-size: 1.2rem;
                text-align: left;

                &.center {
                    text-align: center;
                }

                .td__loading {
                    .MuiCircularProgress-root {
                        width: 2rem !important;
                        height: 2rem !important;

                        svg {
                            color: ${(props) => props.theme.palette.gray[400]};
                        }
                    }
                }
            }
        }
    }
`
