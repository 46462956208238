import React from 'react'
import { CircularProgress } from '@material-ui/core'

import Container, { ButtonContainer } from './styles'

export const ButtonsContainer = ButtonContainer

export default ({ loading, disabled, children, onClick, ...rest }) => {
    return (
        <Container
            className={`${loading ? 'button__loading' : ''} ${
                disabled ? 'disabled' : ''
            }`}
            type="button"
            {...rest}
            onClick={!disabled ? onClick : null}
        >
            {loading ? (
                <CircularProgress className="button__loader" />
            ) : (
                children
            )}
        </Container>
    )
}
