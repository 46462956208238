import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export default styled(Dialog)`
    .MuiDialog-paper {
        max-width: 90%;
        padding: 2rem;
        border-radius: 1.6rem;
    }
`
