export default {
    inova: {
        primary: '#cb184b',
        contrast: 'white',
    },
    credilink: {
        primary: '#e33435',
        contrast: 'white',
    },
}
