import styled from 'styled-components'

export const Panel = styled.div`
    background-color: white;
    border-radius: 1.6rem;
    margin-top: 1rem;
    width: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
`

export const PanelTabs = styled.ul`
    background-color: ${(props) => props.theme.palette.gray[300]};
    border-radius: 0.8rem 0.8rem 0 0;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    display: flex;

    li {
        button {
            padding: 2rem 4rem;
            font-size: 1.4rem;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            color: ${(props) => props.theme.palette.gray[600]};
            outline: none;
            width: 100%;
            font-weight: 600;

            &.selected {
                background-color: white;
                border-radius: 0.8rem 0.8rem 0 0;
                cursor: default;
                font-weight: 700;
                color: black;
            }
        }
    }
`

export const PanelBody = styled.div`
    padding: 2rem;
`

export const PanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: black;
`
