import { createContext } from 'react'

export const Store = createContext()

export const initialState = {
    lists: {
        singleList: [],
        groupList: [],
    },
}
