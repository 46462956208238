import React from 'react'

export const Logo = ({ className }) => (
    <img
        className={className}
        src={`assets/${process.env.REACT_APP_THEME}/logo.png`}
        alt=""
    />
)

export const LogoMin = (className) => (
    <img
        className={className}
        src={`assets/${process.env.REACT_APP_THEME}/logo_min.png`}
        alt=""
    />
)
