import styled from 'styled-components'

export default styled.div`
    margin-left: 4rem;
    margin-top: 4rem;

    .modules {
        display: flex;
        align-items: center;
        margin-top: 4rem;

        &__card {
            margin: 2rem;
            margin-left: 0;
            background-color: ${(props) => props.theme.palette.gray[200]};
            border-radius: 0.8rem;
            padding: 4rem;
            height: 10rem;
            width: 30rem;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 600;
            color: ${(props) => props.theme.palette.gray[700]};
            cursor: pointer;
            text-decoration: none;

            &:hover {
                color: ${(props) => props.theme.palette.primary};
            }
        }

        &__icon {
            margin-right: 1rem;
        }
    }
`
