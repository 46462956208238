import styled from 'styled-components'
import { Popover } from '@material-ui/core'

export default styled(Popover)`
    .MuiPopover-paper {
        background-color: white;
        font-size: 1.4rem;
        display: flex;
        flex-direction: column;
        border-radius: 1.6rem;
        backdrop-filter: blur(5px);
        padding: 2rem;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;

        .floatingMenuForm {
            &__body {
                margin-bottom: 2rem;
            }

            &__period {
                display: flex;
                margin-top: 2rem;

                & > div:not(:last-child) {
                    margin-right: 2rem;
                }
            }
        }
    }

    .MuiPaper-elevation8 {
        box-shadow: none;
    }

    .code_text {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
`
