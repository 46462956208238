import React from 'react'
import { Tooltip } from '@material-ui/core'

import Container from './styles'
import Icons from '../Icons'

export default ({ icon, onClick, label = '', placement, primary }) => {
    return (
        <Container>
            <Tooltip title={label} placement={placement || 'bottom'}>
                <div onClick={onClick}>
                    <div
                        className={`iconButton__icon ${
                            primary ? 'primary' : ''
                        }`}
                    >
                        <Icons icon={icon} />
                    </div>
                    {/*<div className="iconButton__background" />*/}
                </div>
            </Tooltip>
        </Container>
    )
}
