import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export default styled.div`
    .emailChecker {
        margin-top: 4rem;

        &__content {
            margin-top: 4rem;
        }
    }

    .panel_container {
        margin-top: 2rem;
    }

    .singleForm {
        &__body {
            display: flex;
        }

        &__document {
            width: 20rem;
            margin-right: 2rem;
        }
    }

    .groupForm {
        &__name {
            width: 40rem;
        }
    }

    .historyLoading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20rem;

        svg {
            color: ${(props) => props.theme.palette.gray[400]};
        }
    }

    .downloadContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    }

    .historyResults {
        margin-top: 4rem;

        .filterButton {
            cursor: pointer;
        }
    }

    .historyContainer {
        margin-top: 2rem;

        &__resultDay {
            margin-bottom: 2rem;
        }

        &__date {
            font-size: 1.4rem;
            font-weight: 600;
            padding: 1rem 2rem;
            background-color: ${(props) => props.theme.palette.gray[200]};
            border-radius: 0.8rem;
        }
    }

    .historyNoResults {
        width: 100%;
        padding: 4rem;
        text-align: center;
        font-size: 1.6rem;
        color: ${(props) => props.theme.palette.gray[500]};
    }

    .expansionContent {
        max-height: 35rem;
        overflow: auto;
    }

    .dropzone {
        background-color: ${(props) => props.theme.palette.gray[100]};
        border: 2px dashed ${(props) => props.theme.palette.gray[300]};
        border-radius: 0.8rem;
        padding: 2rem;
        outline: none;
        cursor: pointer;
        margin-top: 2rem;

        &--error {
            border-color: ${(props) => props.theme.palette.error};
            background-color: #f5eaea;
        }

        &--active {
            border-color: green;
            background-color: #e0ebe3;
        }
    }

    .dropzone_text {
        width: 100%;
        text-align: center;
        font-weight: 600;
        color: ${(props) => props.theme.palette.gray[500]};
        font-size: 1.2rem;
        padding: 2rem 0;

        &--error {
            color: ${(props) => props.theme.palette.error};
        }

        &--active {
            color: green;
        }

        &.align_center {
            padding: 0;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 2rem;
            font-size: 1rem;
        }
    }

    .dropzone_error {
        text-align: right;
        font-weight: 600;
        font-size: 1rem;
        margin-top: 0.3rem;
        color: ${(props) => props.theme.palette.error};
    }

    .categoriesModal {
        font-size: 1.4rem;
    }

    .csvInfo {
        display: flex;
        justify-content: center;

        &__btn {
            border: 0;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            color: ${(props) => props.theme.palette.primary};
            font-size: 1.2rem;
        }
    }
`

export const CustomModal = styled(Dialog)`
    .MuiDialog-paper {
        width: 90%;
        max-width: 68rem;
        padding: 2rem;
        border-radius: 1.6rem;
    }

    .modal__loading {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20rem;
    }

    .modalHeader {
        margin-bottom: 2rem;

        &__14 {
            font-size: 1.4rem;

            strong {
                width: 10rem;
                display: inline-block;
                margin-bottom: 0.5rem;
            }
        }
    }

    .modalBody {
        &__title {
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 0.5rem;
        }

        &__mediumWidth {
            width: 45rem;
            display: flex;
            flex-wrap: wrap;
        }
    }

    .modal {
        &__head,
        &__info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2rem;
        }

        &__title {
            font-size: 2rem;
            font-weight: 700;
            color: black;
        }

        &__infoText {
            font-size: 1.4rem;
            font-weight: 600;
            color: ${(props) => props.theme.palette.gray[600]};
        }

        &__body {
            max-height: 35rem;
            overflow: auto;
        }

        &__noResults {
            font-size: 1.2rem;
            padding: 2rem;
            color: ${(props) => props.theme.palette.gray[600]};
            width: 100%;
            text-align: center;
        }
    }

    .propensionSingleModal {
        &__header {
            font-size: 1.4rem;
            margin-bottom: 2rem;
            display: flex;

            & > p {
                margin-right: 2rem;
            }
        }

        &__category {
            font-size: 1.4rem;
            font-weight: 600;
            text-transform: uppercase;
            margin-bottom: 0.5rem;
        }

        &__content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }

        &__noResults {
            font-size: 1.2rem;
            padding-top: 1rem;
            padding-bottom: 2rem;
            color: ${(props) => props.theme.palette.gray[600]};
        }

        &__backBtn {
            border: 0;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            font-weight: 600;
            font-size: 1.4rem;
            color: ${(props) => props.theme.palette.primary};
            margin-bottom: 2rem;
            display: flex;
            align-items: center;
        }
    }
`
