import styled from 'styled-components'

export const Title = styled.h1`
    font-size: 2.8rem;
    font-weight: 700;
    color: ${(props) => props.theme.palette.gray[800]};
    margin-top: 5rem;
`

export const SubTitle = styled.p`
    font-size: 1.4rem;
    color: ${(props) => props.theme.palette.gray[500]};
    margin-top: 0.5rem;
`
