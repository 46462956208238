import XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import moment from 'moment'

export default function(data) {
    const wb = XLSX.utils.book_new()
    wb.Props = {
        Title: `${data.title}_${moment().format('DD-MM-YYYY')}`,
        Author: 'Hub - Powered by InovaMind',
        CreatedDate: moment(),
    }
    wb.SheetNames.push('Sheet1')

    const ws_data = [data.header, ...data.values]
    const ws = XLSX.utils.aoa_to_sheet(ws_data)
    wb.Sheets['Sheet1'] = ws

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

    FileSaver.saveAs(
        new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
        `${data.filename}_${moment().format('DD-MM-YYYY')}.xlsx`,
    )
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
    return buf
}
