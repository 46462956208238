import styled from 'styled-components'

export default styled.div`
    border-radius: 0.8rem;
    border: 1px solid ${(props) => props.theme.palette.gray[300]};
    background-color: white;
    font-size: 1.4rem;
    height: 4rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 23rem;

    input {
        border: 0;
        outline: none;
        margin-right: 1rem;
        width: 100%;
    }

    .icon {
        height: 2.4rem;
        margin-right: 1rem;

        svg {
            color: ${(props) => props.theme.palette.gray[300]};
        }
    }
`
