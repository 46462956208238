import styled from 'styled-components'

export default styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('assets/background.png') no-repeat right bottom;
    background-size: contain;

    .animatedContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .logo {
        max-height: 10rem;
        margin-bottom: 4rem;
    }

    .powered {
        position: absolute;
        bottom: 4rem;
        right: 4rem;
        height: 4rem;
    }

    .textinput {
        margin-bottom: 2rem;
    }
`
