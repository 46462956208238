import styled from 'styled-components'

export default styled.div`
    cursor: pointer;
    position: relative;
    border-radius: 3rem;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .iconButton {
        &__background {
            background-color: rgba(0, 0, 0, 0.15);
            position: absolute;
            width: 150%;
            height: 140%;
            border-radius: 50%;
            transform: translate(-13%, -80%) scale(0);
            transition: all 0.2s ease-in-out;
            top: 30px;
            left: -2px;
            z-index: 0;
        }

        &__icon {
            position: relative;
            z-index: 4;
            &.primary {
                svg {
                    color: ${(props) => props.theme.palette.primary};
                }
            }
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.15);
    }
`
