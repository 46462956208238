import React, { useState, useEffect, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'
import {
    TextInput,
    Button,
    ButtonsContainer,
    PanelHeader,
    Table,
    FloatingMenu,
    Icons,
    IconButton,
    Expansion,
    DatePicker,
    Pill,
} from '@hub/components'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { messages, cpfValidator, groupByDate, excelExporter } from '@hub/config'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { maskCpfCnpj } from '@hub/config'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'
import { ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'

import Container, { CustomModal } from './styles'
import { get, post, getApiKey } from '../../service/api'

const validationSchema = Yup.object({
    document: Yup.string()
        .test('valid', messages.invalidCpf, (val) => {
            if (val) return cpfValidator(val)
            return false
        })
        .required(messages.required),
    email: Yup.string()
        .email(messages.invalidEmail)
        .required(messages.required),
})

const initialValues = {
    document: '',
    email: '',
}

const filterValidationSchema = Yup.object({
    start: Yup.string().required(messages.required),
    end: Yup.string().required(messages.required),
    search: Yup.string(),
})

const filterInitialValues = {
    type: 'one',
    start: moment().subtract(7, 'd'),
    end: moment(),
    search: '',
}

export default function({ list, onSubmit }) {
    const permissions = useSelector((state) => state.permissions)
    const [loading, setLoading] = useState(false)
    const [historyLoading, setHistoryLoading] = useState(true)
    const [historyList, setHistoryList] = useState([])
    const [searchEl, setSearchEl] = useState(null)
    const [filterInitialData, setFilterInitialData] = useState(
        filterInitialValues,
    )
    const [categoriesOpen, setCategoriesOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})

    const transition = useSpring({
        from: { opacity: 0, transform: 'translate3D(30px, 0, 0)' },
        to: { opacity: 1, transform: 'translate3D(0, 0, 0)' },
    })

    const searchOpen = Boolean(searchEl)

    const handleCheckStatus = async (el) => {
        let retry = true

        if (el.categories === undefined) {
            const res = await get({
                url: `/response/${el.id}`,
                key: getApiKey(permissions.user),
            })

            if (res.status === 'OK') {
                onSubmit(res.data)
                retry = false
            }
        }

        if (retry) {
            setTimeout(() => handleCheckStatus(el), 3000)
        }
    }

    const submitAction = (values) => {
        return post({
            url: '/request',
            key: getApiKey(permissions.user),
            data: {
                email: values.email,
                document: values.document,
                response_type: 'score_only',
            },
        })
    }

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true)
        const exists = list.some(
            (item) =>
                item.document === values.document &&
                item.email === values.email,
        )

        if (exists) {
            setLoading(false)
            return toast.error('Este documento e email já foram enviados!')
        }

        const res = await submitAction(values)

        if (res.status === 'OK') {
            const item = {
                email: values.email,
                document: values.document,
                id: res.id,
            }
            onSubmit(item)
            resetForm({})
            handleCheckStatus(item)
        }
        setLoading(false)
    }

    const handleSearch = useCallback(
        async (data) => {
            setHistoryLoading(true)
            setFilterInitialData(data)

            const res = await post({
                url: '/search',
                key: getApiKey(permissions.user),
                data: {
                    type: 'one',
                    start: data.start.format('DD-MM-YYYY'),
                    end: data.end.format('DD-MM-YYYY'),
                    search: data.search,
                },
            })

            if (res.status === 'OK') {
                setHistoryList(groupByDate(res.result))
            }
            setHistoryLoading(false)
        },
        [permissions.user],
    )

    useEffect(() => {
        handleSearch(filterInitialValues)
    }, [handleSearch])

    const handleSearchButtonClick = (event) => {
        setSearchEl(event.currentTarget)
    }

    const handleSearchClose = () => {
        setSearchEl(null)
    }

    const handleFilterSubmit = async (values) => {
        handleSearchClose()
        handleSearch(values)
    }

    const handleSearchAgain = async (el) => {
        setLoading(true)
        const res = await submitAction(el)

        if (res.status === 'OK') {
            const item = {
                email: el.email,
                document: el.document,
                id: res.id,
            }
            onSubmit(item)
            handleCheckStatus(item)
        }
        setLoading(false)
    }

    const handleShowCategories = (item) => {
        setSelectedItem(item)
        setCategoriesOpen(true)
    }

    const handleClose = () => {
        setCategoriesOpen(false)
    }

    const handleSingleDownload = () => {
        let tableData = []

        for (const dateGroup of historyList) {
            tableData.push([dateGroup.label])

            if (
                moment().format('DD/MM/YYYY') === dateGroup.label &&
                list.length > 0
            ) {
                for (const item of list) {
                    tableData.push([
                        item.document,
                        item.email,
                        item.categories.join(', '),
                    ])
                }
            }

            for (const item of dateGroup.options) {
                tableData.push([
                    item.document,
                    item.email,
                    item.categories.join(', '),
                ])
            }
        }

        const data = {
            title: 'EmailPropensao',
            header: ['DOCUMENTO', 'EMAIL', 'CATEGORIAS'],
            values: tableData,
            filename: 'emailPropensao',
        }

        excelExporter(data)
    }

    return (
        <React.Fragment>
            <CustomModal open={categoriesOpen} onClose={handleClose}>
                <div className="propensionSingleModal__header">
                    <p>
                        <strong>Email:</strong> {selectedItem.email}
                    </p>
                    <p>
                        <strong>Documento:</strong>
                        {maskCpfCnpj(selectedItem.document)}
                    </p>
                </div>

                <p className="propensionSingleModal__category">Categorias</p>
                <div className="propensionSingleModal__content">
                    {selectedItem.categories &&
                    selectedItem.categories.length > 0 ? (
                        selectedItem.categories.map((item, index) => (
                            <Pill key={index}>{item}</Pill>
                        ))
                    ) : (
                        <div className="propensionSingleModal__noResults">
                            Nenhuma categoria encontrada
                        </div>
                    )}
                </div>
            </CustomModal>

            <animated.div style={transition}>
                <Container>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(props) => {
                            return (
                                <form
                                    onSubmit={props.handleSubmit}
                                    className="singleForm"
                                >
                                    <div className="singleForm__body">
                                        <div className="singleForm__document">
                                            <Field
                                                autoComplete="new-cpf"
                                                name="document"
                                                label="CPF"
                                                component={TextInput}
                                                mask="cpf"
                                            />
                                        </div>
                                        <Field
                                            autoComplete="new-email"
                                            name="email"
                                            label="Email"
                                            component={TextInput}
                                        />
                                    </div>

                                    <ButtonsContainer>
                                        <Button
                                            onClick={props.handleSubmit}
                                            loading={loading}
                                        >
                                            Enviar
                                        </Button>
                                    </ButtonsContainer>
                                </form>
                            )
                        }}
                    </Formik>

                    {(list.length > 0 || historyList.length > 0) && (
                        <div className="downloadContainer">
                            <IconButton
                                icon="download"
                                label="Baixar listagem"
                                onClick={handleSingleDownload}
                            />
                        </div>
                    )}

                    {list.length > 0 && (
                        <div className="historyResults">
                            <Expansion defaultExpanded>
                                <ExpansionPanelSummary
                                    expandIcon={<Icons icon="chevronDown" />}
                                >
                                    Nesta sessão
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="expansionContent">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="width100" />
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>
                                                            {maskCpfCnpj(
                                                                item.document,
                                                            )}{' '}
                                                            - {item.email}
                                                        </td>
                                                        <td className="center">
                                                            {item.categories ===
                                                            undefined ? (
                                                                <div className="td__loading">
                                                                    <CircularProgress />
                                                                </div>
                                                            ) : (
                                                                <IconButton
                                                                    icon="list"
                                                                    label="Ver categorias"
                                                                    primary
                                                                    onClick={() =>
                                                                        handleShowCategories(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </ExpansionPanelDetails>
                            </Expansion>
                        </div>
                    )}

                    {historyLoading ? (
                        <div className="historyLoading">
                            <CircularProgress />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="historyResults">
                                <PanelHeader>
                                    <span>
                                        Histórico de Consultas anteriores
                                    </span>
                                    <div
                                        onClick={handleSearchButtonClick}
                                        type="button"
                                        className="filterButton"
                                    >
                                        Filtros <Icons icon="chevronDown" />
                                    </div>

                                    <FloatingMenu
                                        open={searchOpen}
                                        anchorEl={searchEl}
                                        onClose={handleSearchClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Formik
                                            initialValues={filterInitialData}
                                            validationSchema={
                                                filterValidationSchema
                                            }
                                            onSubmit={handleFilterSubmit}
                                        >
                                            {(props) => {
                                                const { values } = props
                                                return (
                                                    <form
                                                        onSubmit={
                                                            props.handleSubmit
                                                        }
                                                        className="floatingMenuForm"
                                                    >
                                                        <div className="floatingMenuForm__body">
                                                            <Field
                                                                name="search"
                                                                label="Palavra chave"
                                                                component={
                                                                    TextInput
                                                                }
                                                            />
                                                            <div className="floatingMenuForm__period">
                                                                <Field
                                                                    name="start"
                                                                    label="Data Inicial"
                                                                    component={
                                                                        DatePicker
                                                                    }
                                                                />
                                                                <Field
                                                                    name="end"
                                                                    label="Data Final"
                                                                    component={
                                                                        DatePicker
                                                                    }
                                                                    disableFuture={
                                                                        true
                                                                    }
                                                                    minDate={
                                                                        values.start
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <ButtonsContainer>
                                                            <Button
                                                                onClick={
                                                                    props.handleSubmit
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                            >
                                                                Filtrar
                                                            </Button>
                                                        </ButtonsContainer>
                                                    </form>
                                                )
                                            }}
                                        </Formik>
                                    </FloatingMenu>
                                </PanelHeader>

                                {historyList.length > 0 ? (
                                    <div className="historyContainer">
                                        {historyList.map((item, index) => (
                                            <Expansion
                                                key={`${item.id}${index}`}
                                            >
                                                <ExpansionPanelSummary
                                                    expandIcon={
                                                        <Icons icon="chevronDown" />
                                                    }
                                                >
                                                    {item.label}
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <div className="expansionContent">
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="width100" />
                                                                    <th />
                                                                    <th />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.options.map(
                                                                    (el) => (
                                                                        <tr
                                                                            key={
                                                                                el.id
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {maskCpfCnpj(
                                                                                    el.document,
                                                                                )}{' '}
                                                                                -{' '}
                                                                                {
                                                                                    el.email
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <IconButton
                                                                                    icon="list"
                                                                                    label="Ver categorias"
                                                                                    primary
                                                                                    onClick={() =>
                                                                                        handleShowCategories(
                                                                                            el,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <IconButton
                                                                                    icon="refresh"
                                                                                    label="Consultar novamente"
                                                                                    primary
                                                                                    onClick={() =>
                                                                                        handleSearchAgain(
                                                                                            el,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ),
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </ExpansionPanelDetails>
                                            </Expansion>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="historyNoResults">
                                        Nenhum resultado encontrado
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </Container>
            </animated.div>
        </React.Fragment>
    )
}
