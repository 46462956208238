import styled from 'styled-components'

export default styled.div`
    width: 100%;

    .textinput {
        width: 100%;

        &__label {
            display: block;
            font-weight: 600;
            font-size: 1.2rem;
            margin-bottom: 0.3rem;

            &--error {
                color: ${(props) => props.theme.palette.error};
            }
        }

        &__input {
            width: 100%;
            border-radius: 0.4rem;
            border: 1px solid ${(props) => props.theme.palette.gray[400]};
            height: 3.5rem;
            outline: none;
            padding: 0 1rem;
            font-size: 1.2rem;

            &--error {
                border-color: ${(props) => props.theme.palette.error};
            }
        }

        &__error {
            text-align: right;
            font-weight: 600;
            font-size: 1rem;
            margin-top: 0.3rem;
            color: ${(props) => props.theme.palette.error};
        }
    }
`
