import styled from 'styled-components'

export default styled.div`
    padding: 2rem;
    width: 100%;
    max-width: 40rem;

    .loginForm__error {
        color: #e82323;
        font-size: 1.2rem;
        padding: 1rem;
        width: 100%;
        text-align: center;
        font-weight: 600;
    }
`
