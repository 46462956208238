import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import createRootReducer from './reducers'
import sagas from './sagas'

export const localStorageKey = 'inovamind_hub'

const persistConfig = {
    key: localStorageKey,
    storage,
    whitelist: ['user', 'permissions'],
}

export const history = createBrowserHistory()

const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history),
)

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(routerMiddleware(history), sagaMiddleware),
    ),
)

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export { store, persistor }
