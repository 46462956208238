export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

const initialState = {
    loading: false,
    error: '',
}

export default function auth(state = initialState, { type, payload }) {
    switch (type) {
        case LOGIN_REQUEST:
            return {
                loading: true,
                error: '',
            }
        case LOGIN_SUCCESS:
            return {
                ...initialState,
            }
        case LOGIN_FAILURE:
            return {
                loading: false,
                error: payload,
            }
        default:
            return state
    }
}

export function login(form) {
    return {
        type: LOGIN_REQUEST,
        payload: form,
    }
}
