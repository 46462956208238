export const cpfValidator = (c) => {
    const replaced = c.replace(/[^\d]/g, '')
    if (replaced.length !== 11) return false

    if (replaced === '00000000000') return false

    let r
    let s = 0

    for (let i = 1; i <= 9; i++) s += parseInt(replaced[i - 1], 10) * (11 - i)

    r = (s * 10) % 11

    if (r === 10 || r === 11) r = 0

    if (r !== parseInt(replaced[9], 10)) return false

    s = 0

    for (let i = 1; i <= 10; i++) s += parseInt(replaced[i - 1], 10) * (12 - i)

    r = (s * 10) % 11

    if (r === 10 || r === 11) r = 0

    if (r !== parseInt(replaced[10], 10)) return false

    return true
}

export const cnpjValidator = (s) => {
    let cnpj = s.replace(/[^\d]+/g, '')

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) return false

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) return false

    // Cáculo de validação
    let t = cnpj.length - 2,
        d = cnpj.substring(t),
        d1 = parseInt(d.charAt(0)),
        d2 = parseInt(d.charAt(1)),
        calc = (x) => {
            let n = cnpj.substring(0, x),
                y = x - 7,
                s = 0,
                r = 0

            for (let i = x; i >= 1; i--) {
                s += n.charAt(x - i) * y--
                if (y < 2) y = 9
            }

            r = 11 - (s % 11)
            return r > 9 ? 0 : r
        }

    return calc(t) === d1 && calc(t + 1) === d2
}

// export const passwordValidator = new RegExp(
//     '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})',
// )
export const passwordValidator = (password) => {
    const pattern = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})',
    )
    let valid = pattern.test(password)

    if (valid) {
        const array = password.split('')
        array.forEach((elem) => {
            const qty = array.filter((item) => item === elem)
            if (qty.length > 3) valid = false
        })
    }

    return valid
}

export const emailValidator = (mail) =>
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
