import styled from 'styled-components'

export default styled.p`
    display: inline;
    border-radius: 3rem;
    background-color: ${(props) => props.theme.palette.gray[300]};
    color: ${(props) => props.theme.palette.gray[800]};
    font-size: 1.2rem;
    padding: 1rem;
    margin: 0.5rem;
`
