import React, { useCallback, useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import {
    TextInput,
    Button,
    ButtonsContainer,
    PanelHeader,
    Table,
    FloatingMenu,
    Icons,
    Expansion,
    DatePicker,
    IconButton,
    SearchInput,
} from '@hub/components'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import {
    messages,
    groupByGroupDate,
    maskCpfCnpj,
    excelExporter,
} from '@hub/config'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import { ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import moment from 'moment'

import Container, { CustomModal } from './styles'
import { get, post, getApiKey } from '../../service/api'

const defaultUploadMessage =
    'Arraste um arquivo CSV para cá, ou clique para procurar em seu dispositivo'

const validationSchema = Yup.object({
    name: Yup.string().required(messages.required),
    file: Yup.mixed().required(messages.required),
})

const initialValues = {
    name: '',
    file: null,
}

const filterValidationSchema = Yup.object({
    start: Yup.string().required(messages.required),
    end: Yup.string().required(messages.required),
    search: Yup.string(),
})

const filterInitialValues = {
    type: 'group',
    start: moment().subtract(7, 'd'),
    end: moment(),
    search: '',
}

const acceptedFormats = ['csv']

export default function({ list, onSubmit }) {
    const [uploadMessage, setUploadMessage] = useState(defaultUploadMessage)
    const permissions = useSelector((state) => state.permissions)
    const [loading, setLoading] = useState(false)
    const [historyLoading, setHistoryLoading] = useState(true)
    const [historyList, setHistoryList] = useState([])
    const [searchEl, setSearchEl] = useState(null)
    const [filterInitialData, setFilterInitialData] = useState(
        filterInitialValues,
    )
    const [open, setOpen] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [modalDataItems, setModalDataItems] = useState([])
    const [csvInfoEl, setCsvInfoEl] = useState(null)

    const searchOpen = Boolean(searchEl)
    const csvInfoOpen = Boolean(csvInfoEl)

    const transition = useSpring({
        from: { opacity: 0, transform: 'translate3D(-30px, 0, 0)' },
        to: { opacity: 1, transform: 'translate3D(0, 0, 0)' },
    })

    let globalSetFieldValue = null

    const submitAction = (values) => {
        const formData = new FormData()
        formData.append('file', values.file)

        return post({
            url: `/group/request/csv?name=${values.name}`,
            key: getApiKey(permissions.user),
            data: formData,
        })
    }

    const handleCheckStatus = async (el) => {
        let retry = true

        if (!el.finished) {
            const res = await get({
                url: `/group/response/${el.id}`,
                key: getApiKey(permissions.user),
            })

            if (res.status === 'OK') {
                onSubmit({ id: res.id, ...res.result })

                if (res.result.finished) {
                    retry = false
                }
            }
        }

        if (retry) {
            setTimeout(() => handleCheckStatus(el), 3000)
        }
    }

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true)

        const res = await submitAction(values)

        if (res.status === 'OK') {
            const item = {
                name: values.name,
                id: res.group,
                finished: false,
            }
            onSubmit(item)
            resetForm({})
            setUploadMessage(defaultUploadMessage)
            handleCheckStatus(item)
        }
        setLoading(false)
    }

    const checkFileType = (name) => {
        const array = name.split('.')
        return acceptedFormats.includes(array[array.length - 1].toLowerCase())
    }

    const handleOnDrop = useCallback(
        (acceptedFiles) => {
            if (!checkFileType(acceptedFiles[0].name)) {
                toast.error('Somente arquivos CSV são permitidos!')
            } else {
                setUploadMessage(acceptedFiles[0].name)
                globalSetFieldValue('file', acceptedFiles[0])
            }
        },
        [globalSetFieldValue],
    )

    const handleSearch = useCallback(
        async (data) => {
            setHistoryLoading(true)
            setFilterInitialData(data)

            const res = await post({
                url: '/search',
                key: getApiKey(permissions.user),
                data: {
                    type: 'group',
                    start: data.start.format('DD-MM-YYYY'),
                    end: data.end.format('DD-MM-YYYY'),
                    search: data.search,
                },
            })

            if (res.status === 'OK') {
                setHistoryList(groupByGroupDate(res.result))
            }
            setHistoryLoading(false)
        },
        [permissions.user],
    )

    useEffect(() => {
        handleSearch(filterInitialValues)
    }, [handleSearch])

    const handleSearchButtonClick = (event) => {
        setSearchEl(event.currentTarget)
    }

    const handleSearchClose = () => {
        setSearchEl(null)
    }

    const handleFilterSubmit = async (values) => {
        handleSearchClose()
        handleSearch(values)
    }

    const handleOpenResultsModal = async (el) => {
        setOpen(true)
        setModalLoading(true)

        const res = await get({
            url: `/group/details/${el.id}`,
            key: getApiKey(permissions.user),
        })

        if (res.status === 'OK') {
            setModalData({ id: res.id, ...res.result })
            setModalDataItems(res.result.items)
        }

        setModalLoading(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleModalFilter = (term) => {
        if (term.trim().length > 0) {
            const newList = modalData.items.filter((item) => {
                const preparedEmail = item.email
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                const exists =
                    preparedEmail.toLowerCase().includes(term) ||
                    item.document.includes(term)
                return exists
            })
            setModalDataItems(newList)
        } else {
            setModalDataItems(modalData.items)
        }
    }

    const handleSingleDownload = () => {
        let tableData = []

        for (const item of modalDataItems) {
            tableData.push([item.document, item.email, item.score])
        }

        const data = {
            title: modalData.name,
            header: ['DOCUMENTO', 'EMAIL', 'SCORE'],
            values: tableData,
            filename: modalData.name.split(' ').join(''),
        }

        excelExporter(data)
    }

    const handleCsvInfoOpen = (event) => {
        setCsvInfoEl(event.currentTarget)
    }

    const handleCsvInfoClose = () => {
        setCsvInfoEl(null)
    }

    return (
        <React.Fragment>
            <CustomModal open={open} onClose={handleClose}>
                {modalLoading ? (
                    <div className="modal__loading">
                        <CircularProgress />
                    </div>
                ) : (
                    <div className="modal">
                        <div className="modal__head">
                            <div className="modal__title">{modalData.name}</div>
                            <SearchInput
                                icon="search"
                                onChange={handleModalFilter}
                            />
                        </div>
                        <div className="modal__info">
                            <div className="modal__infoText">
                                Total de itens: {modalData.total}
                            </div>
                            <IconButton
                                icon="download"
                                label="Baixar listagem"
                                onClick={handleSingleDownload}
                            />
                        </div>
                        <div className="modal__body">
                            <Table>
                                <thead>
                                    <tr>
                                        <th className="width100" />
                                        <th className="center">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalDataItems.length > 0 &&
                                        modalDataItems.map((item) => (
                                            <tr key={item.id}>
                                                <td>{`${maskCpfCnpj(
                                                    item.document,
                                                )} - ${item.email}`}</td>
                                                <td className="center">
                                                    <strong>
                                                        {item.score}
                                                    </strong>
                                                </td>
                                            </tr>
                                        ))}

                                    {modalDataItems.length === 0 && (
                                        <tr>
                                            <td
                                                colspan="2"
                                                className="modal__noResults"
                                            >
                                                Nenhum resultado encontrado
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                )}
            </CustomModal>

            <animated.div style={transition}>
                <Container>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(props) => {
                            const { errors, setFieldValue } = props
                            globalSetFieldValue = setFieldValue

                            return (
                                <form
                                    onSubmit={props.handleSubmit}
                                    className="groupForm"
                                >
                                    <div className="groupForm__name">
                                        <Field
                                            name="name"
                                            label="Nome do Grupo"
                                            component={TextInput}
                                        />
                                    </div>

                                    <Dropzone
                                        onDrop={handleOnDrop}
                                        multiple={false}
                                        noKeyboard={true}
                                    >
                                        {({
                                            getRootProps,
                                            getInputProps,
                                            isDragActive,
                                        }) => (
                                            <div
                                                className={`dropzone ${
                                                    isDragActive
                                                        ? 'dropzone--active'
                                                        : ''
                                                } ${
                                                    errors['file']
                                                        ? 'dropzone--error'
                                                        : ''
                                                }`}
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <p
                                                    className={`dropzone_text ${
                                                        isDragActive
                                                            ? 'dropzone_text--active'
                                                            : ''
                                                    } ${
                                                        errors['file']
                                                            ? 'dropzone_text--error'
                                                            : ''
                                                    }`}
                                                >
                                                    {uploadMessage}
                                                </p>
                                            </div>
                                        )}
                                    </Dropzone>

                                    <p className="dropzone_error">
                                        {errors['file']}
                                    </p>

                                    <p className="dropzone_text align_center">
                                        Apenas arquivos CSV, limite de 5.000
                                        linhas por arquivo
                                    </p>

                                    <div className="csvInfo">
                                        <button
                                            type="button"
                                            className="csvInfo__btn"
                                            onClick={handleCsvInfoOpen}
                                        >
                                            Clique aqui para ver um modelo do
                                            CSV a ser enviado
                                        </button>

                                        <FloatingMenu
                                            open={csvInfoOpen}
                                            anchorEl={csvInfoEl}
                                            onClose={handleCsvInfoClose}
                                            anchorOrigin={{
                                                vertical: 'center',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'center',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <div className="code_text">
                                                EMAIL;DOCUMENT
                                                <br />
                                                email1@email.com;11111111111
                                                <br />
                                                email2@email.com;22222222222
                                                <br />
                                                email3@email.com;33333333333
                                                <br />
                                                email4@email.com;44444444444
                                                <br />
                                            </div>
                                        </FloatingMenu>
                                    </div>

                                    <ButtonsContainer>
                                        <Button
                                            onClick={props.handleSubmit}
                                            loading={loading}
                                        >
                                            Enviar
                                        </Button>
                                    </ButtonsContainer>
                                </form>
                            )
                        }}
                    </Formik>

                    {list.length > 0 && (
                        <div className="historyResults">
                            <Expansion defaultExpanded>
                                <ExpansionPanelSummary
                                    expandIcon={<Icons icon="chevronDown" />}
                                >
                                    Nesta sessão
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <div className="expansionContent">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="emailCheckerGroupName" />
                                                    <th className="center">
                                                        Itens Processados
                                                    </th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td className="center">
                                                            {item.total &&
                                                                `${item.processed}/${item.total}`}
                                                        </td>
                                                        <td className="center">
                                                            {!item.finished ? (
                                                                <div className="td__loading">
                                                                    <CircularProgress />
                                                                </div>
                                                            ) : (
                                                                <IconButton
                                                                    icon="list"
                                                                    label="Ver resultados"
                                                                    onClick={() =>
                                                                        handleOpenResultsModal(
                                                                            item,
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </ExpansionPanelDetails>
                            </Expansion>
                        </div>
                    )}

                    {historyLoading ? (
                        <div className="historyLoading">
                            <CircularProgress />
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className="historyResults">
                                <PanelHeader>
                                    <span>
                                        Histórico de Consultas anteriores
                                    </span>
                                    <div
                                        onClick={handleSearchButtonClick}
                                        type="button"
                                        className="filterButton"
                                    >
                                        Filtros <Icons icon="chevronDown" />
                                    </div>

                                    <FloatingMenu
                                        open={searchOpen}
                                        anchorEl={searchEl}
                                        onClose={handleSearchClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <Formik
                                            initialValues={filterInitialData}
                                            validationSchema={
                                                filterValidationSchema
                                            }
                                            onSubmit={handleFilterSubmit}
                                        >
                                            {(props) => {
                                                const { values } = props
                                                return (
                                                    <form
                                                        onSubmit={
                                                            props.handleSubmit
                                                        }
                                                        className="floatingMenuForm"
                                                    >
                                                        <div className="floatingMenuForm__body">
                                                            <Field
                                                                name="search"
                                                                label="Palavra chave"
                                                                component={
                                                                    TextInput
                                                                }
                                                            />
                                                            <div className="floatingMenuForm__period">
                                                                <Field
                                                                    name="start"
                                                                    label="Data Inicial"
                                                                    component={
                                                                        DatePicker
                                                                    }
                                                                />
                                                                <Field
                                                                    name="end"
                                                                    label="Data Final"
                                                                    component={
                                                                        DatePicker
                                                                    }
                                                                    disableFuture={
                                                                        true
                                                                    }
                                                                    minDate={
                                                                        values.start
                                                                    }
                                                                />
                                                            </div>
                                                        </div>

                                                        <ButtonsContainer>
                                                            <Button
                                                                onClick={
                                                                    props.handleSubmit
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                            >
                                                                Filtrar
                                                            </Button>
                                                        </ButtonsContainer>
                                                    </form>
                                                )
                                            }}
                                        </Formik>
                                    </FloatingMenu>
                                </PanelHeader>

                                {historyList.length > 0 ? (
                                    <div className="historyContainer">
                                        {historyList.map((item, index) => (
                                            <Expansion
                                                key={`${item.id}${index}`}
                                            >
                                                <ExpansionPanelSummary
                                                    expandIcon={
                                                        <Icons icon="chevronDown" />
                                                    }
                                                >
                                                    {item.label}
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <div className="expansionContent">
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="emailCheckerGroupName" />
                                                                    <th className="center">
                                                                        Itens
                                                                        Processados
                                                                    </th>
                                                                    <th />
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {item.options.map(
                                                                    (el) => (
                                                                        <tr
                                                                            key={
                                                                                el.id
                                                                            }
                                                                        >
                                                                            <td>
                                                                                {
                                                                                    el.name
                                                                                }
                                                                            </td>
                                                                            <td className="center">
                                                                                {`${el.processed}/${el.total}`}
                                                                            </td>
                                                                            <td className="center">
                                                                                {el.finished && (
                                                                                    <IconButton
                                                                                        icon="search"
                                                                                        label="Ver resultados"
                                                                                        primary
                                                                                        onClick={() =>
                                                                                            handleOpenResultsModal(
                                                                                                el,
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    ),
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </ExpansionPanelDetails>
                                            </Expansion>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="historyNoResults">
                                        Nenhum resultado encontrado
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    )}
                </Container>
            </animated.div>
        </React.Fragment>
    )
}
