import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

import Container from './styles'
import Login from 'ui/views/Login'
import { Logo } from 'assets'

export default function({ component: Component, ...rest }) {
    const transition = useSpring({
        from: { opacity: 0, transform: 'translate3D(0, 20px, 0)' },
        to: { opacity: 1, transform: 'translate3D(0, 0, 0)' },
    })

    return (
        <Route
            {...rest}
            render={() => (
                <Container>
                    <animated.div
                        style={transition}
                        className="animatedContainer"
                    >
                        <Logo className="logo" />

                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route component={Login} />
                        </Switch>
                    </animated.div>

                    {process.env.REACT_APP_THEME === 'inova' && (
                        <img
                            src="assets/powered.svg"
                            alt="Powered by InovaMind"
                            className="powered"
                        />
                    )}
                </Container>
            )}
        />
    )
}
