import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import axios from 'axios'

import { baseUrl } from 'service/api'
import { LOGIN_SUCCESS, LOGIN_FAILURE } from 'store/reducers/auth'
import { USER_REQUEST } from 'store/reducers/user'
import { SET_USER_PERMISSIONS } from 'store/reducers/permissions'

function axiosPost(params) {
    return axios({
        method: 'POST',
        url: `${baseUrl}${params.url}`,
        headers: { 'Content-Type': 'application/json' },
        data: params.payload,
    })
}

export function* handleLogin(action) {
    try {
        const { email, password } = action.payload
        const res = yield call(axiosPost, {
            url: '/account/auth',
            payload: { email, password },
        })

        if (res) {
            const {
                id,
                name,
                email,
                creation,
                token,
                username,
                is_admin,
                organization,
            } = res.data

            const userData = {
                id,
                name,
                email,
                creation,
                token,
                username,
                is_admin,
            }

            yield put({
                type: USER_REQUEST,
                payload: userData,
            })

            yield put({
                type: SET_USER_PERMISSIONS,
                payload: organization.keys,
            })

            yield put({
                type: LOGIN_SUCCESS,
            })

            yield put(push(action.payload.from || '/home'))
        }
    } catch (e) {
        if (e.response) {
            if (e.response.status === 500) {
                yield put({
                    type: LOGIN_FAILURE,
                    payload: 'Erro ao se conectar com servidor',
                })
            } else {
                yield put({
                    type: LOGIN_FAILURE,
                    payload: e.response.data.message,
                })
            }
        }
    }
}
