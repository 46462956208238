export const USER_REQUEST = 'USER_REQUEST'
export const USER_LOGOUT = 'USER_LOGOUT'

const initialState = {}

export default function user(state = initialState, { type, payload }) {
    switch (type) {
        case USER_REQUEST:
            return {
                ...payload,
            }
        case USER_LOGOUT:
            return {
                ...initialState,
            }
        default:
            return state
    }
}

export function logout() {
    return {
        type: USER_LOGOUT,
    }
}

export function updateUser(payload) {
    return {
        type: USER_REQUEST,
        payload,
    }
}
