import React from 'react'
import { DatePicker } from '@material-ui/pickers'

import Container from './styles'

export default function(props) {
    const { label, field, form } = props
    const { errors, setFieldValue } = form
    const { name } = field

    function handleChange(value) {
        setFieldValue(name, value)
    }

    return (
        <Container>
            <div className="textinput">
                <label
                    className={`textinput__label ${
                        errors[name] ? 'textinput__label--error' : ''
                    }`}
                    htmlFor={name}
                >
                    {label}
                </label>

                <DatePicker
                    value={field.value}
                    className={`textinput__input ${
                        errors[name] ? 'textinput__input--error' : ''
                    }`}
                    disableFuture={props.disableFuture}
                    minDate={props.minDate}
                    id={name}
                    onChange={(value) => handleChange(value)}
                    format="DD/MM/YYYY"
                />
            </div>
        </Container>
    )
}
