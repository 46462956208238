import styled from 'styled-components'

export default styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    margin: 0.4rem 0;

    li {
        a {
            color: ${(props) => props.theme.palette.gray[800]};
            font-size: 1.4rem;
            padding: 1rem 1.5rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            text-decoration: none;
            outline: none;
            border-radius: 0.8rem;
            cursor: pointer;

            &:hover {
                background-color: ${(props) => props.theme.palette.gray[100]};
            }

            & > svg {
                width: 1.8rem;
                margin-right: 0.5rem;
            }

            &.selected {
                color: ${(props) => props.theme.palette.primary};
            }
        }
    }
`
