import moment from 'moment'

export const groupByDate = (list) => {
    const res = list.reduce((re, o) => {
        const existObj = re.find((obj) => {
            return moment(obj.label, 'DD/MM/YYYY').isSame(
                moment(o.date, 'DD-MM-YYYY'),
            )
        })
        if (existObj) {
            existObj.options.push(o)
        } else {
            re.push({
                label: moment(o.date, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                options: [o],
            })
        }
        return re
    }, [])

    return res
}

export const groupByGroupDate = (list) => {
    const res = list.reduce((re, o) => {
        const existObj = re.find((obj) => {
            return moment(obj.label, 'DD/MM/YYYY').isSame(
                moment(o.result.date, 'DD-MM-YYYY'),
            )
        })
        if (existObj) {
            existObj.options.push({ id: o.id, ...o.result })
        } else {
            re.push({
                label: moment(o.result.date, 'DD-MM-YYYY').format('DD/MM/YYYY'),
                options: [{ id: o.id, ...o.result }],
            })
        }
        return re
    }, [])

    return res
}
