export default {
    auth: {
        development: 'https://api.hub.inovamind.app',
        production: 'https://api.hub.inovamind.app',
    },
    emailchecker: {
        development: 'https://api.inova6.space/email-checker/v1',
        production: 'https://api.inova6.space/email-checker/v1',
    },
    emailpropensity: {
        development: 'https://api.inova6.space/tendency/v1',
        production: 'https://api.inova6.space/tendency/v1',
    },
}
