import React, { useState, useContext } from 'react'
import { Title, SubTitle, Tabs } from '@hub/components'
import { useSpring, animated } from 'react-spring'

import Container from './styles'
import Single from './Single'
import Group from './Group'
import { Store } from '../../store'
import { setSingleList, setGroupList } from '../../store/reducers/listsReducer'

const tabsList = [{ label: 'Individual' }, { label: 'Em Grupo' }]

export default function() {
    const [tabSelected, setTabSelected] = useState(0)
    const [state, dispatch] = useContext(Store)
    const {
        lists: { singleList, groupList },
    } = state

    const transition = useSpring({
        from: { opacity: 0, transform: 'translate3D(0, 30px, 0)' },
        to: { opacity: 1, transform: 'translate3D(0, 0, 0)' },
    })

    const handleSingleSubmit = (payload) => {
        dispatch(setSingleList(payload))
    }

    const handleGroupSubmit = (payload) => {
        dispatch(setGroupList(payload))
    }

    const handleTabChange = (index) => {
        setTabSelected(index)
    }

    return (
        <animated.div style={transition}>
            <Container>
                <Title>Email Checker</Title>
                <SubTitle>Escolha um modo de consulta</SubTitle>

                <div className="emailChecker">
                    <Tabs tabs={tabsList} onChange={handleTabChange} />

                    <div className="emailChecker__content">
                        {tabSelected === 0 && (
                            <Single
                                list={singleList}
                                onSubmit={handleSingleSubmit}
                            />
                        )}
                        {tabSelected === 1 && (
                            <Group
                                list={groupList}
                                onSubmit={handleGroupSubmit}
                            />
                        )}
                    </div>
                </div>
            </Container>
        </animated.div>
    )
}
