import React from 'react'

import Container from './styles'
import Icons from '../Icons'

export default function({ icon, onChange, className }) {
    return (
        <Container className={className}>
            <div className="icon">
                <Icons icon={icon} />
            </div>
            <input
                type="text"
                placeholder="Pesquisar"
                onChange={(e) => onChange(e.target.value)}
            />
        </Container>
    )
}
