import React, { useReducer } from 'react'
import styled from 'styled-components'

import EmailChecker from './ui/views'
import { Store, initialState } from './store'
import rootReducer from './store/reducers'

const Container = styled.div`
    width: 100%;
    max-width: 67rem;
    margin-left: 4rem;
    margin-top: 4rem;
`

export default function() {
    const store = useReducer(rootReducer, initialState)

    return (
        <Store.Provider value={store}>
            <Container>
                <EmailChecker />
            </Container>
        </Store.Provider>
    )
}
