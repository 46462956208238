import React from 'react'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from 'store/reducers/user'

import useRouter from 'utils/useRouter'

export default function({ parent: Parent, child, matchProps, ...rest }) {
    const { token } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const router = useRouter()

    function returnToLogin() {
        router.history.push({
            pathname: '/',
            state: { from: router.location.pathname },
        })
    }

    if (token) {
        const decoded = jwtDecode(token)
        const current_time = Date.now() / 1000
        if (decoded.exp < current_time) {
            toast.warn('Seu acesso expirou, faça login novamente.')
            dispatch(logout())
            returnToLogin()
            return null
        }
        return <Parent component={child} {...rest} />
    }

    toast.error('Acesso não autorizado!')
    returnToLogin()
    return null
}
