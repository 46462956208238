import React from 'react'
import { Title, SubTitle, Icons } from '@hub/components'
import { useSpring, animated } from 'react-spring'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Container from './styles'

export default function() {
    const permissions = useSelector((state) => state.permissions)

    const transition = useSpring({
        from: { opacity: 0, transform: 'translate3D(0, 30px, 0)' },
        to: { opacity: 1, transform: 'translate3D(0, 0, 0)' },
    })

    const handleCheckMenuPermission = (permission) => {
        if (permissions.user.some((item) => item.slug === permission))
            return true
        return false
    }

    const handleRedirect = (url) => {
        document.location.href = document.location.origin + url
    }

    return (
        <animated.div style={transition}>
            <Container>
                <Title>HUB</Title>
                <SubTitle>Selecione um módulo para iniciar</SubTitle>

                <div className="modules">
                    {handleCheckMenuPermission('email-checker') && (
                        <Link to="/emailchecker" className="modules__card">
                            <div className="modules__icon">
                                <Icons icon="emailChecker" />
                            </div>
                            Email Checker
                        </Link>
                    )}

                    {handleCheckMenuPermission('email-tendency') && (
                        <Link to="/emailpropensity" className="modules__card">
                            <div className="modules__icon">
                                <Icons icon="emailPropensity" />
                            </div>
                            Email Propensão
                        </Link>
                    )}
                </div>
            </Container>
        </animated.div>
    )
}
