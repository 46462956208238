import styled from 'styled-components'

export default styled.button`
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.contrast};
    border: 1px solid transparent;
    border-radius: 3rem;
    width: 100%;
    max-width: 40rem;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
    height: 4rem;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    transition: all 0.3s ease;

    &.outline {
        background-color: transparent;
        color: ${(props) => props.theme.palette.primary};
        border: 1px solid ${(props) => props.theme.palette.primary};
    }

    &:focus {
        outline: none;
    }

    &.disabled {
        cursor: default;
        background-color: ${(props) => props.theme.palette.gray[300]};
        color: ${(props) => props.theme.palette.gray[500]};
    }

    &.button__loading {
        cursor: default !important;
        background-color: ${(props) => props.theme.palette.gray[300]};
        width: 4rem;
        padding: 0.5rem;
    }

    .button__loader {
        width: 3rem !important;
        height: 3rem !important;

        svg {
            width: 3rem;
            height: 3rem;
        }
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
`
