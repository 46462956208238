import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    transition: all 0.3s ease-in-out;

    @keyframes spinner {
        to {
            transform: rotate(360deg);
        }
    }

    .spinner:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        height: 30px;
        margin-top: -15px;
        margin-left: -15px;
        border-radius: 50%;
        border: 2px solid ${(props) => props.theme.palette.gray[300]};
        border-top-color: ${(props) => props.theme.palette.primary};
        animation: spinner 0.6s linear infinite;
    }
`

export default function() {
    return (
        <Container>
            <div className="spinner" />
        </Container>
    )
}
