export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const SET_SYS_PERMISSIONS = 'SET_SYS_PERMISSIONS'

const initialState = {
    user: [],
    system: [],
}

export default function permissions(state = initialState, { type, payload }) {
    switch (type) {
        case SET_USER_PERMISSIONS:
            return {
                ...state,
                user: payload,
            }
        case SET_SYS_PERMISSIONS:
            return {
                ...state,
                system: payload.system,
            }
        default:
            return state
    }
}
