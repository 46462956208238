import styled from 'styled-components'
import { ExpansionPanel } from '@material-ui/core'

export default styled(ExpansionPanel)`
    margin-top: 1rem;

    &.MuiPaper-root {
        background-color: transparent;
    }

    &.MuiPaper-elevation1 {
        box-shadow: none;
    }

    &.MuiExpansionPanel-root {
        width: 100%;
        margin: 0;
        margin-bottom: 1rem;
    }

    &.MuiExpansionPanel-root:before {
        background-color: transparent;
    }

    &.MuiExpansionPanel-root.Mui-expanded {
        margin: 0;
    }

    .MuiExpansionPanelSummary-root {
        padding: 1rem 2rem;
        min-height: 0;
        background-color: ${(props) => props.theme.palette.gray[200]};
        border-radius: 0.8rem;

        &.Mui-expanded {
            min-height: 0 !important;
        }
    }

    .MuiExpansionPanelSummary-content {
        font-weight: bold;
        font-size: 1.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
    }

    .MuiExpansionPanelDetails-root {
        padding: 1rem 0;
        flex-direction: column;
    }

    .MuiExpansionPanelSummary-root.Mui-expanded {
        min-height: 4.8rem;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
        padding: 0;
    }

    .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 0;
    }
`
