import React from 'react'
import { Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { history } from 'store'

import DefaultLayout from 'ui/layouts/Default'
import SignedInLayout from 'ui/layouts/SignedIn'
import SecureRoute from './SecureRoute'

import Home from 'ui/views/Home'
import EmailChecker from '@hub/emailchecker'
import EmailPropensity from '@hub/emailpropensity'

export default () => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <DefaultLayout exact path="/" />
                <SecureRoute
                    path="/home"
                    parent={SignedInLayout}
                    child={Home}
                />
                <SecureRoute
                    path="/emailchecker"
                    parent={SignedInLayout}
                    child={EmailChecker}
                />
                <SecureRoute
                    path="/emailpropensity"
                    parent={SignedInLayout}
                    child={EmailPropensity}
                />
            </Switch>
        </ConnectedRouter>
    )
}
