import React from 'react'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { Button, TextInput, ButtonsContainer } from '@hub/components'
import { useSelector, useDispatch } from 'react-redux'

import Container from './styles'
import { login } from 'store/reducers/auth'

const validationSchema = Yup.object({
    email: Yup.string().required('Campo obrigatório'),
    password: Yup.string().required('Campo obrigatório'),
})

const initialValues = {
    email: '',
    password: '',
}

export default function({ location }) {
    const auth = useSelector((state) => state.auth)
    const dispatch = useDispatch()

    const { state } = location
    const { loading, error } = auth

    const handleSubmit = (values) => {
        const { email, password } = values
        dispatch(login({ email, password, from: state ? state.from : null }))
    }

    return (
        <Container>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => {
                    return (
                        <form onSubmit={props.handleSubmit}>
                            <Field
                                name="email"
                                label="Email ou Nome de usuário"
                                component={TextInput}
                            />
                            <Field
                                type="password"
                                autoComplete="new-password"
                                name="password"
                                label="Senha"
                                component={TextInput}
                            />

                            {error && (
                                <div className="loginForm__error">{error}</div>
                            )}

                            <ButtonsContainer>
                                <Button
                                    onClick={props.handleSubmit}
                                    loading={loading}
                                >
                                    Entrar
                                </Button>
                            </ButtonsContainer>
                        </form>
                    )
                }}
            </Formik>
        </Container>
    )
}
