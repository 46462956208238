import React from 'react'
import MaskedInput from 'react-text-mask'
import { cpfMask } from '@hub/config'

import Container from './styles'

const masks = {
    cpf: cpfMask,
}

export default (props) => {
    const { label, field, form, mask } = props
    const { errors, setFieldValue } = form
    const { name } = field

    function handleChange(value) {
        setFieldValue(name, value)
    }

    return (
        <Container>
            <div className="textinput">
                <label
                    className={`textinput__label ${
                        errors[name] ? 'textinput__label--error' : ''
                    }`}
                    htmlFor={name}
                >
                    {label}
                </label>
                {!mask ? (
                    <input
                        {...props}
                        {...field}
                        className={`textinput__input ${
                            errors[name] ? 'textinput__input--error' : ''
                        }`}
                        id={name}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                ) : (
                    <MaskedInput
                        {...props}
                        {...field}
                        mask={masks[mask]}
                        className={`textinput__input ${
                            errors[name] ? 'textinput__input--error' : ''
                        }`}
                        id={name}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                )}

                <div className="textinput__error">{errors[name]}</div>
            </div>
        </Container>
    )
}
