import React, { useState, useEffect, useCallback } from 'react'
import { useSpring, animated } from 'react-spring'
import {
    TextInput,
    Button,
    ButtonsContainer,
    PanelHeader,
    Table,
    FloatingMenu,
    Icons,
    IconButton,
    Expansion,
    DatePicker,
} from '@hub/components'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import { messages, cpfValidator, groupByDate, excelExporter } from '@hub/config'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { maskCpfCnpj } from '@hub/config'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'
import { ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'

import Container from './styles'
import { get, post, getApiKey } from '../../service/api'

const validationSchema = Yup.object({
    document: Yup.string()
        .test('valid', messages.invalidCpf, (val) => {
            if (val) return cpfValidator(val)
            return false
        })
        .required(messages.required),
    email: Yup.string()
        .email(messages.invalidEmail)
        .required(messages.required),
})

const initialValues = {
    document: '',
    email: '',
}

const filterValidationSchema = Yup.object({
    start: Yup.string().required(messages.required),
    end: Yup.string().required(messages.required),
    search: Yup.string(),
})

const filterInitialValues = {
    type: 'one',
    start: moment().subtract(7, 'd'),
    end: moment(),
    search: '',
}

export default function({ list, onSubmit }) {
    const permissions = useSelector((state) => state.permissions)
    const [loading, setLoading] = useState(false)
    const [historyLoading, setHistoryLoading] = useState(true)
    const [historyList, setHistoryList] = useState([])
    const [searchEl, setSearchEl] = useState(null)
    const [filterInitialData, setFilterInitialData] = useState(
        filterInitialValues,
    )

    const transition = useSpring({
        from: { opacity: 0, transform: 'translate3D(30px, 0, 0)' },
        to: { opacity: 1, transform: 'translate3D(0, 0, 0)' },
    })

    const searchOpen = Boolean(searchEl)

    const handleCheckStatus = async (el) => {
        let retry = true

        if (el.score === undefined) {
            const res = await get({
                url: `/response/${el.id}`,
                key: getApiKey(permissions.user),
            })

            if (res.status === 'OK') {
                onSubmit(res.data)
                retry = false
            }
        }

        if (retry) {
            setTimeout(() => handleCheckStatus(el), 3000)
        }
    }

    const submitAction = (values) => {
        return post({
            url: '/request',
            key: getApiKey(permissions.user),
            data: {
                email: values.email,
                document: values.document,
                response_type: 'score_only',
            },
        })
    }

    const handleSubmit = async (values, { resetForm }) => {
        setLoading(true)
        const exists = list.some(
            (item) =>
                item.document === values.document &&
                item.email === values.email,
        )

        if (exists) {
            setLoading(false)
            return toast.error('Este documento e email já foram enviados!')
        }

        const res = await submitAction(values)

        if (res.status === 'OK') {
            const item = {
                email: values.email,
                document: values.document,
                id: res.id,
            }
            onSubmit(item)
            resetForm({})
            handleCheckStatus(item)
        }
        setLoading(false)
    }

    const handleSearch = useCallback(
        async (data) => {
            setHistoryLoading(true)
            setFilterInitialData(data)

            const res = await post({
                url: '/search',
                key: getApiKey(permissions.user),
                data: {
                    type: 'one',
                    start: data.start.format('DD-MM-YYYY'),
                    end: data.end.format('DD-MM-YYYY'),
                    search: data.search,
                },
            })

            if (res.status === 'OK') {
                setHistoryList(groupByDate(res.result))
            }
            setHistoryLoading(false)
        },
        [permissions.user],
    )

    useEffect(() => {
        handleSearch(filterInitialValues)
    }, [handleSearch])

    const handleSearchButtonClick = (event) => {
        setSearchEl(event.currentTarget)
    }

    const handleSearchClose = () => {
        setSearchEl(null)
    }

    const handleFilterSubmit = async (values) => {
        handleSearchClose()
        handleSearch(values)
    }

    const handleSingleDownload = () => {
        let tableData = []

        for (const dateGroup of historyList) {
            tableData.push([dateGroup.label])

            if (
                moment().format('DD/MM/YYYY') === dateGroup.label &&
                list.length > 0
            ) {
                for (const item of list) {
                    tableData.push([item.document, item.email, item.score])
                }
            }

            for (const item of dateGroup.options) {
                tableData.push([item.document, item.email, item.score])
            }
        }

        const data = {
            title: 'EmailChecker',
            header: ['DOCUMENTO', 'EMAIL', 'SCORE'],
            values: tableData,
            filename: 'emailChecker',
        }

        excelExporter(data)
    }

    const handleSearchAgain = async (el) => {
        setLoading(true)
        const res = await submitAction(el)

        if (res.status === 'OK') {
            const item = {
                email: el.email,
                document: el.document,
                id: res.id,
            }
            onSubmit(item)
            handleCheckStatus(item)
        }
        setLoading(false)
    }

    return (
        <animated.div style={transition}>
            <Container>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props) => {
                        return (
                            <form
                                onSubmit={props.handleSubmit}
                                className="singleForm"
                            >
                                <div className="singleForm__body">
                                    <div className="singleForm__document">
                                        <Field
                                            autoComplete="new-cpf"
                                            name="document"
                                            label="CPF"
                                            component={TextInput}
                                            mask="cpf"
                                        />
                                    </div>
                                    <Field
                                        autoComplete="new-email"
                                        name="email"
                                        label="Email"
                                        component={TextInput}
                                    />
                                </div>

                                <ButtonsContainer>
                                    <Button
                                        onClick={props.handleSubmit}
                                        loading={loading}
                                    >
                                        Enviar
                                    </Button>
                                </ButtonsContainer>
                            </form>
                        )
                    }}
                </Formik>

                {(list.length > 0 || historyList.length > 0) && (
                    <div className="downloadContainer">
                        <IconButton
                            icon="download"
                            label="Baixar listagem"
                            onClick={handleSingleDownload}
                        />
                    </div>
                )}

                {list.length > 0 && (
                    <div className="historyResults">
                        <Expansion defaultExpanded>
                            <ExpansionPanelSummary
                                expandIcon={<Icons icon="chevronDown" />}
                            >
                                Nesta sessão
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="expansionContent">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th className="emailCheckerSingleName" />
                                                <th className="center">
                                                    Score
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        {maskCpfCnpj(
                                                            item.document,
                                                        )}{' '}
                                                        - {item.email}
                                                    </td>
                                                    <td className="center">
                                                        {item.score ===
                                                        undefined ? (
                                                            <div className="td__loading">
                                                                <CircularProgress />
                                                            </div>
                                                        ) : (
                                                            <strong>
                                                                {item.score}
                                                            </strong>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </ExpansionPanelDetails>
                        </Expansion>
                    </div>
                )}

                {historyLoading ? (
                    <div className="historyLoading">
                        <CircularProgress />
                    </div>
                ) : (
                    <React.Fragment>
                        <div className="historyResults">
                            <PanelHeader>
                                <span>Histórico de Consultas anteriores</span>
                                <div
                                    onClick={handleSearchButtonClick}
                                    type="button"
                                    className="filterButton"
                                >
                                    Filtros <Icons icon="chevronDown" />
                                </div>

                                <FloatingMenu
                                    open={searchOpen}
                                    anchorEl={searchEl}
                                    onClose={handleSearchClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <Formik
                                        initialValues={filterInitialData}
                                        validationSchema={
                                            filterValidationSchema
                                        }
                                        onSubmit={handleFilterSubmit}
                                    >
                                        {(props) => {
                                            const { values } = props
                                            return (
                                                <form
                                                    onSubmit={
                                                        props.handleSubmit
                                                    }
                                                    className="floatingMenuForm"
                                                >
                                                    <div className="floatingMenuForm__body">
                                                        <Field
                                                            name="search"
                                                            label="Palavra chave"
                                                            component={
                                                                TextInput
                                                            }
                                                        />
                                                        <div className="floatingMenuForm__period">
                                                            <Field
                                                                name="start"
                                                                label="Data Inicial"
                                                                component={
                                                                    DatePicker
                                                                }
                                                            />
                                                            <Field
                                                                name="end"
                                                                label="Data Final"
                                                                component={
                                                                    DatePicker
                                                                }
                                                                disableFuture={
                                                                    true
                                                                }
                                                                minDate={
                                                                    values.start
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <ButtonsContainer>
                                                        <Button
                                                            onClick={
                                                                props.handleSubmit
                                                            }
                                                            loading={loading}
                                                        >
                                                            Filtrar
                                                        </Button>
                                                    </ButtonsContainer>
                                                </form>
                                            )
                                        }}
                                    </Formik>
                                </FloatingMenu>
                            </PanelHeader>

                            {historyList.length > 0 ? (
                                <div className="historyContainer">
                                    {historyList.map((item, index) => (
                                        <Expansion key={`${item.id}${index}`}>
                                            <ExpansionPanelSummary
                                                expandIcon={
                                                    <Icons icon="chevronDown" />
                                                }
                                            >
                                                {item.label}
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <div className="expansionContent">
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th className="emailCheckerSingleName" />
                                                                <th className="center">
                                                                    Score
                                                                </th>
                                                                <th />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.options.map(
                                                                (el) => (
                                                                    <tr
                                                                        key={
                                                                            el.id
                                                                        }
                                                                    >
                                                                        <td>{`${maskCpfCnpj(
                                                                            el.document,
                                                                        )} - ${
                                                                            el.email
                                                                        }`}</td>
                                                                        <td className="center">
                                                                            {!el.score &&
                                                                            el.score !==
                                                                                0 ? (
                                                                                <small>
                                                                                    Processando
                                                                                </small>
                                                                            ) : (
                                                                                <strong>
                                                                                    {
                                                                                        el.score
                                                                                    }
                                                                                </strong>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <IconButton
                                                                                icon="refresh"
                                                                                label="Consultar novamente"
                                                                                primary
                                                                                onClick={() =>
                                                                                    handleSearchAgain(
                                                                                        el,
                                                                                    )
                                                                                }
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ),
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </ExpansionPanelDetails>
                                        </Expansion>
                                    ))}
                                </div>
                            ) : (
                                <div className="historyNoResults">
                                    Nenhum resultado encontrado
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </Container>
        </animated.div>
    )
}
