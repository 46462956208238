export const SET_SINGLE_LIST = 'SET_SINGLE_LIST'
export const SET_GROUP_LIST = 'SET_GROUP_LIST'

const initialState = {
    singleList: [],
    groupList: [],
}

export default function auth(state = initialState, { type, payload }) {
    switch (type) {
        case SET_SINGLE_LIST:
            if (state.singleList.length > 0) {
                const exists = state.singleList.some(
                    (el) => el.id === payload.id,
                )

                if (exists) {
                    const newList = state.singleList.map((el) => {
                        if (el.id === payload.id) el = payload
                        return el
                    })

                    return {
                        ...state,
                        singleList: newList,
                    }
                }

                return {
                    ...state,
                    singleList: [payload, ...state.singleList],
                }
            }
            return {
                ...state,
                singleList: [payload, ...state.singleList],
            }

        case SET_GROUP_LIST:
            if (state.groupList.length > 0) {
                const exists = state.groupList.some(
                    (el) => el.id === payload.id,
                )

                if (exists) {
                    const newList = state.groupList.map((el) => {
                        if (el.id === payload.id) el = payload
                        return el
                    })

                    return {
                        ...state,
                        groupList: newList,
                    }
                }

                return {
                    ...state,
                    groupList: [payload, ...state.groupList],
                }
            }
            return {
                ...state,
                groupList: [payload, ...state.groupList],
            }

        default:
            return state
    }
}

export function setSingleList(payload) {
    return {
        type: SET_SINGLE_LIST,
        payload,
    }
}

export function setGroupList(payload) {
    return {
        type: SET_GROUP_LIST,
        payload,
    }
}
