import axios from 'axios'
// import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'
import { clientUrls } from '@hub/config'

export const baseUrl = clientUrls.emailchecker[process.env.NODE_ENV]

export const getApiKey = (permissions) => {
    const key = permissions.find((item) => item.slug === 'email-checker')
    return key.api_key
}

export const get = async ({ url, key, history }) => {
    return await service('GET', url, key, null, history)
}

export const post = async ({ url, key, data, history }) => {
    return await service('POST', url, key, data, history)
}

export const servicePut = async ({ url, key, data, history }) => {
    return await service('PUT', url, key, data, history)
}

export const del = async ({ url, key, data, history }) => {
    return await service('DELETE', url, key, data, history)
}

const service = async (method, url, key, data, history) => {
    if (key) {
        // const decoded = jwtDecode(key)
        // const current_time = Date.now() / 1000

        // if (decoded.exp < current_time) {
        //     toast.error('Seu acesso expirou.')
        //     return history.replace('/')
        // } else {
        try {
            const res = await axios({
                method: method,
                url: `${baseUrl}${url}`,
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': key,
                },
                data: data ? data : null,
            })
            return res.data
        } catch (error) {
            if (error.response) {
                if (error.response.status === 500) {
                    toast.error(
                        'Erro interno no servidor. Tente novamente mais tarde.',
                    )
                } else {
                    toast.error(error.response.data.message)
                }
            } else {
                toast.error('Houve um erro ao realizar esta ação')
            }

            return false
        }
        // }
    } else {
        toast.error('Acesso não autorizado!')
    }
}

export const postFile = async ({ url, key, data, history }) => {
    return await serviceFile('POST', url, key, data, history)
}

export const putFile = async ({ url, key, data, history }) => {
    return await serviceFile('PUT', url, key, data, history)
}

export const serviceFile = async (method, url, key, data, history) => {
    // try {
    //     const decoded = jwtDecode(key)
    //     const current_time = Date.now() / 1000

    //     if (decoded.exp < current_time) {
    //         toast.error('Seu acesso expirou.')
    //         return history.replace('/')
    //     } else {
    try {
        const res = await axios({
            method,
            url: `${baseUrl}${url}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': key,
            },
            data,
        })
        return res.data
    } catch (error) {
        if (error.response) {
            if (error.response.status === 500) {
                toast.error(
                    'Erro interno no servidor. Tente novamente mais tarde.',
                )
            } else {
                toast.error(error.response.data.message)
            }
        } else {
            toast.error('Houve um erro ao realizar esta ação')
        }
    }
    //     }
    // } catch (e) {
    //     toast.error('Token de acesso inválido')
    // }
}
