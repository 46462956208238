import React, { useState } from 'react'
import { Route, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Popover, IconButton, Icons } from '@hub/components'

import Container from './styles'
import { persistor } from 'store'
import { LogoMin } from 'assets'

export default function({ component: Component, ...rest }) {
    const user = useSelector((state) => state.user)
    const permissions = useSelector((state) => state.permissions)
    const [menuEl, setMenuEl] = useState(null)

    const menuOpen = Boolean(menuEl)

    const handleCheckMenuPermission = (permission) => {
        if (permissions.user.some((item) => item.slug === permission))
            return true
        return false
    }

    const handleMenuButtonClick = (event) => {
        setMenuEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setMenuEl(null)
    }

    const handleLogout = () => {
        persistor.purge()
        document.location.href = '/'
    }

    const generateUserInitials = () => {
        const nameArray = user.name.split(' ')
        let initials = ''
        for (const name of nameArray) {
            if (initials.length < 2) {
                initials += name.charAt(0).toUpperCase()
            }
        }

        return initials
    }

    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <Container>
                    <aside className="aside">
                        <div className="aside__section">
                            <div className="aside__logo">
                                <LogoMin />
                            </div>

                            <div className="aside__item">
                                <Link to="/home">
                                    <IconButton
                                        icon="hub"
                                        label="Hub"
                                        placement="right"
                                        onClick={() => {}}
                                    />
                                </Link>
                            </div>

                            {handleCheckMenuPermission('email-checker') && (
                                <div className="aside__item">
                                    <Link to="/emailchecker">
                                        <IconButton
                                            icon="emailChecker"
                                            label="Email Checker"
                                            placement="right"
                                            onClick={() => {}}
                                        />
                                    </Link>
                                </div>
                            )}

                            {handleCheckMenuPermission('email-tendency') && (
                                <div className="aside__item">
                                    <Link to="/emailpropensity">
                                        <IconButton
                                            icon="emailPropensity"
                                            label="Email Propensão"
                                            placement="right"
                                            onClick={() => {}}
                                        />
                                    </Link>
                                </div>
                            )}
                        </div>

                        <div className="aside__section">
                            <button
                                className="aside__menu-button"
                                type="button"
                                onClick={handleMenuButtonClick}
                            >
                                {generateUserInitials()}
                                <Icons icon="menu" />
                            </button>

                            <Popover
                                open={menuOpen}
                                anchorEl={menuEl}
                                onClose={handleMenuClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {/*<button
                                    type="button"
                                    onClick={() => handleRedirect('/profile')}
                                >
                                    Meu Perfil
                                </button>*/}
                                <button type="button" onClick={handleLogout}>
                                    Sair
                                </button>
                            </Popover>
                        </div>
                    </aside>
                    <main className="main">
                        <Component {...matchProps} />
                    </main>
                </Container>
            )}
        />
    )
}
