import styled from 'styled-components'

export default styled.div`
    display: flex;

    .aside {
        background-color: ${(props) => props.theme.palette.primary};
        color: ${(props) => props.theme.palette.contrast};
        height: 100vh;
        position: fixed;
        width: 7rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &__section {
            width: 100%;
        }

        &__logo {
            margin-bottom: 4rem;
            padding: 1rem;
            width: 7rem;

            img {
                width: 100%;
            }
        }

        &__item {
            display: flex;
            justify-content: center;
            padding: 0.5rem 0;

            & > a {
                color: white;
            }
        }

        &__icon {
            width: 3rem;
        }

        &__menu-button {
            border: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2rem;
            font-weight: 600;
            padding: 1rem;
            padding-left: 1.5rem;
            background-color: transparent;
            color: #fff;
            cursor: pointer;
            outline: none;
            margin-bottom: 2rem;
        }
    }

    .main {
        padding: 2rem;
        margin-left: 7rem;
        width: 100%;

        &__nav {
            display: flex;
            justify-content: flex-end;
        }

        .user_menu {
            display: flex;
            align-items: center;
            color: ${(props) => props.theme.palette.gray[800]};
            font-size: 1.4rem;
            padding: 1rem 1.5rem;
            position: relative;

            .user_menu__name {
                margin-right: 1rem;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
    }
`
